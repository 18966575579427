// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-culture-biosciences-case-study-js": () => import("/opt/build/repo/src/pages/work/culture-biosciences-case-study.js" /* webpackChunkName: "component---src-pages-work-culture-biosciences-case-study-js" */),
  "component---src-pages-work-facebook-case-study-js": () => import("/opt/build/repo/src/pages/work/facebook-case-study.js" /* webpackChunkName: "component---src-pages-work-facebook-case-study-js" */),
  "component---src-pages-work-peachjar-case-study-js": () => import("/opt/build/repo/src/pages/work/peachjar-case-study.js" /* webpackChunkName: "component---src-pages-work-peachjar-case-study-js" */),
  "component---src-pages-work-safegraph-case-study-js": () => import("/opt/build/repo/src/pages/work/safegraph-case-study.js" /* webpackChunkName: "component---src-pages-work-safegraph-case-study-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

